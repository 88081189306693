<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="carouselSee"
      append-to-body
      :show-close="false"
      :close-on-press-escape="false"
      center
    >
      <el-carousel
        :interval="4000"
        type="card"
        height="200px"
      >
        <el-carousel-item
          v-for="(item,index) in pictureList"
          :key="item+index"
        >
          <!--<h3 class="medium">{{ item }}</h3>-->
          <img
            class="imgs"
            :src="item"
            alt="轮播图"
          >
        </el-carousel-item>
      </el-carousel>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeCarousel">{{this.$t('Confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/fetch/api";

export default {
  name: "Carousel",
  components: {},
  props: {
    carouselFlag: Boolean,
    pictureList: Array,
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      carouselSee: false,
      adminId:data.id,
    };
  },
  watch: {
    carouselFlag: {
      handler(newVal, oldVal) {
        console.log("carouselFlag ->", carouselFlag);
        this.carouselSee = this.carouselFlag;
        /*if (newVal) {
          this.carouselSee = this.carouselFlag
        }*/
      },
    },
  },
  created() {
    this.carouselSee = this.carouselFlag;
  },
  methods: {
    //   关闭弹窗
    closeDialog() {
      this.$emit("closeRefuse", false);
    },

    //   拒绝帖子
    refusePost(id) {
      console.log(" id->", id);
      let param = {
        postId: id,
        sendStatus: 3,
        additional: this.refuseReason,
        currentAdmin:this.adminId
      };
      // this.refuseReason? param.additional = this.refuseReason: delete param.additional
      this.refuseDialogParam.isReceive
        ? (param.chamberId = this.refuseDialogParam)
        : delete param.chamberId;
      console.log("param ->", param);
      api.getUpdatePostById(param).then((res) => {
        // console.log('res ->',res)
        if (res.code === 200) {
          this.$message({
            message: "拒绝成功",
            type: "success",
          });
          this.closeDialog();
        }
      });
    },

    //   关闭弹窗
    closeCarousel() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.imgs {
  display: block;
  height: 200px;
  margin: auto;
}
</style>